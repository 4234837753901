.cadastrado {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  background: #00a49d;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #434360;
  box-shadow: 0px 16px 32px -8px rgba(4, 4, 7, 0.3);
  backdrop-filter: blur(24px);
  border-radius: 8px;
  z-index: 10;
}

.cadastrado .name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #edf4f4;
}

.cadastrado .text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #edf4f4;
  text-align: start;
}

.cadastrado .email {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #edf4f4;
}

.cadastrado .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    width: 85%;
    height: 25px;
    background: #79ba6d;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #edf4f4;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .cadastrado{
        width: 80%;
    }
}